<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table-data
          :title="title"
          :table-headers="tableHeaders"
          :default-table-headers="[]"
          :form-scheme="formScheme"
          :resource="resource"
          :expanded="expanded"
          :show-expand="showExpand"
          :single-expand="singleExpand"
          :item-expanded="getZonesList"
          :item-class-fn="itemClassFn"
          @changeSelected="handleSelected"
          @currentItems="handleCurrentItems"
        >
          <template #top-action-buttons>
            <v-tooltip
              :top="true"
              :bottom="false"
              :left="true"
              :right="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-can="`domains.cloudflare.import`"
                  color="success mr-3"
                  :class="`success ${ selectedItems.length < 1 ? 'd-none' : ' '} `"
                  small
                  :loading="bulkImportDomainsLoading"
                  :disabled="bulkImportDomainsLoading"
                  v-bind="attrs"
                  @click="bulkImportDomains"
                  v-on="on"
                >
                  Import ({{ selectedItems.length }})
                </v-btn>
              </template>
              <span>{{ $t('domains.cloudflare.table.btn.import') }}</span>
            </v-tooltip>
          </template>

          <template #[`item.name`]="{ item }">
            <div class="pt-2 pb-2">
              <div class="mb-1 text-no-wrap">
                {{ item.name }}
                <!--table-btn
                  :tooltip="true"
                  :tooltip-text="`Copy ID to clipboard`"
                  :icon="`identifier`"
                  :class="`copy-email-btn`"
                  :fn="() => {$copyToClipboard(`${item.id}`)}"
                />
              </div-->
                <div
                  class="grey--text small"
                >
                  {{ item.email || '' }}
                  <table-btn
                    :tooltip="true"
                    :tooltip-text="`Copy E-mail to clipboard`"
                    :icon="`at`"
                    :fn="() => {$copyToClipboard(`${item.email}`)}"
                  />
                </div>
              <!--div
                class="grey--text small"
              >
                {{ item.ns_records.join(', ') || '&mdash;' }}
              </div-->
              </div>
            </div>
          </template>
          <template #[`item.team`]="{ item }">
            <div class="pt-2 pb-2">
              <div class="mb-1 text-no-wrap">
                {{ item.team }}
              </div>
            </div>
          </template>
          <template #[`item.ns_records`]="{ item }">
            <div class="text-no-wrap">
              <v-chip-group
                active-class="primary--text"
                column
              >
                <v-chip
                  v-for="(ns, key) in item.ns_records"
                  :key="key"
                  small
                  class="ma-1 chip-with-copy"
                  close
                  close-icon="mdi-content-copy"
                  @click:close="$copyToClipboard(ns)"
                >
                  {{ ns }}
                </v-chip>
              </v-chip-group>
            </div>
          </template>
          <template #[`item.domainsNum`]="{ item }">
            <div class="pt-2 pb-2">
              <div class="mb-1">
                {{ item.domainsNum }} / {{ item.activeDomainsNum }}
              </div>
            </div>
          </template>
          <template #action-buttons="{ item }">
            <table-btn
              :item="item"
              :disabled="item.loading"
              :tooltip-text="$t('domains.cloudflare.table.btn.auth')"
              :icon="`key-chain-variant`"
              :can="'domains.cloudflare.auth'"
              :fn="getCredentials"
            />
            <table-btn
              :item="item"
              :disabled="item.loading"
              :tooltip-text="$t('domains.cloudflare.table.btn.zoneAdd')"
              :icon="`plus`"
              :can="'domains.cloudflare.create'"
              :fn="zoneAdd"
            />
            <table-btn
              :item.sync="item"
              :loading="item.loading"
              :tooltip-text="$t('domains.cloudflare.table.btn.import')"
              :icon="`database-import-outline`"
              :can="'domains.cloudflare.import'"
              :fn="importDomains"
            />
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <component
                :is="detailsComponent"
                :params="item"
              />
            </td>
          </template>
        </table-data>
        <dialogs-cloudflare-zone
          v-if="this.$can('domains.cloudflare.create')"
          :dialog="zoneDialog"
          :edited-item="zoneEditedItem"
          :action="'create'"
          @close="closeZoneDialog"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DialogsCloudflareZone from './dialogs/CloudflareZone'

import CloudflareDetails from './details/CloudflareDetails'

export default {
  name: 'Cloudflare',
  components: {
    DialogsCloudflareZone
  },
  metaInfo () {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('domains.cloudflare.meta.title')} — %s`
    }
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    resource: 'domains.cloudflare',
    currentItems: [],
    selected: {},
    editedItem: {},
    expanded: [],
    showExpand: true,
    singleExpand: true,
    detailsComponent: CloudflareDetails,
    // ZONE DIALOG
    zoneDialog: false,
    zoneFormScheme: {},
    zoneSaveLoading: false,
    zoneEditedItem: {},
    //
    bulkImportDomainsLoading: false
  }),
  computed: {
    getLockedEntities() {
      return this.$store.getters['lockedEntities/getLockedEntities']
    },
    selectedItems () {
      // return this.selected.length > 0
      return this.$store.getters[`__${this.resource}/checkedItems`]
    }
  },
  async mounted () {
    this.title = this.$t('domains.cloudflare.title')

    // let actionsColWight = 20
    // actionsColWight += this.$can('domains.cloudflare.auth') ? 32 : 0
    // actionsColWight += this.$can('domains.cloudflare.import') ? 32 : 0
    // actionsColWight += this.$can('domains.cloudflare.create') ? 32 : 0
    // actionsColWight += this.$can('domains.cloudflare.update') ? 32 : 0
    // actionsColWight += this.$can('domains.cloudflare.remove') ? 32 : 0

    this.tableHeaders = [
      {
        text: this.$t('domains.cloudflare.table.name'),
        align: 'start',
        value: 'name'
      },
      {
        text: this.$t('domains.cloudflare.table.nss'),
        value: 'ns_records',
        width: '150px'
      },
      { text: this.$t('domains.cloudflare.table.zones'), value: 'domainsNum', width: '100px', align: 'center' },
      {
        text: this.$t('tableData.creationDate'),
        align: 'center',
        value: 'createdAt',
        width: '120px'
      }
    ]

    if (
      this.$can('domains.cloudflare.auth') ||
      this.$can('domains.cloudflare.import') ||
      this.$can('domains.cloudflare.create') ||
      this.$can('domains.cloudflare.remove') ||
      this.$can('domains.cloudflare.update')
    ) {
      this.tableHeaders.push({
        text: this.$t('tableData.actions'),
        align: 'center',
        value: 'actions',
        sortable: false,
        // width: `${actionsColWight}px`
        width: '150px'
      })
    }

    // this.updateDomainsCloudflare()

    this.formScheme = {
      name: {
        type: 'text',
        label: this.$t('domains.cloudflare.tableForm.name'),
        createRules: 'req',
        updateRules: []
      },
      email: {
        type: 'text',
        label: this.$t('domains.cloudflare.tableForm.email'),
        createRules: 'req',
        updateRules: []
      },
      password: {
        type: 'password',
        label: this.$t('domains.cloudflare.tableForm.password'),
        ref: 'password',
        data: {
          show: false
        },
        createRules: [],
        updateRules: []
      },
      key: {
        type: 'text',
        label: this.$t('domains.cloudflare.tableForm.key'),
        createRules: 'req',
        updateRules: []
      },
      ns_records: {
        type: 'combobox',
        smallChips: true,
        chips: true,
        multiple: true,
        items: this.editedItem.editedItem,
        label: this.$t('domains.cloudflare.tableForm.nss'),
        createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
        updateRules: [],
        forEditedOnly: true,
        md: 12
      }
    }
    if (this.$currentUser.role === process.env.VUE_APP_MAIN_ROLE || this.$currentUser.global === true) {
      this.tableHeaders = this.$helper.addToArray(this.tableHeaders, {
        text: this.$t('domains.cloudflare.table.team'),
        value: 'team'
      }, 1)
      this.formScheme = this.$helper.addToObject(this.formScheme, 'affiliate_team_id', {
        type: 'autocomplete',
        label: this.$t('domains.cloudflare.tableForm.teams'),
        items: await this.$store.dispatch('affiliatesTeams/all'),
        createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
        updateRules: [],
        md: 12
      }, 'ns_records')
    }
  },
  beforeDestroy() {},
  methods: {
    handleSelected (selected) {
      this.selected = selected
    },
    handleCurrentItems(items) {
      if (items.length > 0) {
        this.currentItems = items
      }
    },
    // updateDomainsCloudflare() {
    //   this.$eventBus.$on('updateDomainsCloudflare', (data) => {
    //     this.$store.commit('__domains.cloudflare/saveItem', data, { root: true })
    //   })
    // },
    async getCredentials ({ id }) {
      const result = await this.$store.dispatch('cloudflare/getCloudflareCredentials', { id })
      if (result.id) {
        await this.$store.dispatch('dialog/dialog', {
          show: true,
          title: this.$t('dialog.credentials'),
          contentType: 'code',
          content: JSON.stringify(result, null, 2),
          maxWidth: 500
        })
      }
    },
    async importDomains (item) {
      try {
        await this.$store.dispatch('cloudflare/importDomains', { id: item.id })
      } catch (e) {
        await this.$toast.error(e.response.data.message)
      }
    },
    /* ------------------------------------------ */
    // ZONES
    /* ------------------------------------------ */
    getZonesList({ item, value }) {},
    zoneAdd ({ id }) {
      this.zoneEditedItem = Object.assign({}, { id })
      this.zoneDialog = true
    },
    closeZoneDialog () {
      this.zoneDialog = false
      this.zoneEditedItem = {}
    },
    itemClassFn(item) {
      let classes
      if (this.getLockedEntities.indexOf(item._id) > -1) {
        classes += ' blue-grey lighten-5 loading-background '
      }
      return classes
    },
    async bulkImportDomains() {
      const ids = Object.keys(this.selected).map((key) => {
        this.$store.commit(`__${this.resource}/setCheckedItems`, { item: this.selected[key], value: false })
        return this.selected[key]._id
      })
      this.bulkImportDomainsLoading = true
      try {
        await this.$store.dispatch('cloudflare/bulkImportDomains', { ids })
      } catch (e) {
        console.log(e)
      } finally {
        this.bulkImportDomainsLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
.copy-email-btn {
  height: 25px !important;
  min-width: 20px !important;
  padding: 0 7px !important;
}
</style>
