var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-data',{attrs:{"title":_vm.title,"table-headers":_vm.tableHeaders,"default-table-headers":[],"form-scheme":_vm.formScheme,"resource":_vm.resource,"expanded":_vm.expanded,"show-expand":_vm.showExpand,"single-expand":_vm.singleExpand,"item-expanded":_vm.getZonesList,"item-class-fn":_vm.itemClassFn},on:{"changeSelected":_vm.handleSelected,"currentItems":_vm.handleCurrentItems},scopedSlots:_vm._u([{key:"top-action-buttons",fn:function(){return [_c('v-tooltip',{attrs:{"top":true,"bottom":false,"left":true,"right":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"can",rawName:"v-can",value:("domains.cloudflare.import"),expression:"`domains.cloudflare.import`"}],class:("success " + (_vm.selectedItems.length < 1 ? 'd-none' : ' ') + " "),attrs:{"color":"success mr-3","small":"","loading":_vm.bulkImportDomainsLoading,"disabled":_vm.bulkImportDomainsLoading},on:{"click":_vm.bulkImportDomains}},'v-btn',attrs,false),on),[_vm._v(" Import ("+_vm._s(_vm.selectedItems.length)+") ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('domains.cloudflare.table.btn.import')))])])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-2 pb-2"},[_c('div',{staticClass:"mb-1 text-no-wrap"},[_vm._v(" "+_vm._s(item.name)+" "),_c('div',{staticClass:"grey--text small"},[_vm._v(" "+_vm._s(item.email || '')+" "),_c('table-btn',{attrs:{"tooltip":true,"tooltip-text":"Copy E-mail to clipboard","icon":"at","fn":function () {_vm.$copyToClipboard(("" + (item.email)))}}})],1)])])]}},{key:"item.team",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-2 pb-2"},[_c('div',{staticClass:"mb-1 text-no-wrap"},[_vm._v(" "+_vm._s(item.team)+" ")])])]}},{key:"item.ns_records",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('v-chip-group',{attrs:{"active-class":"primary--text","column":""}},_vm._l((item.ns_records),function(ns,key){return _c('v-chip',{key:key,staticClass:"ma-1 chip-with-copy",attrs:{"small":"","close":"","close-icon":"mdi-content-copy"},on:{"click:close":function($event){return _vm.$copyToClipboard(ns)}}},[_vm._v(" "+_vm._s(ns)+" ")])}),1)],1)]}},{key:"item.domainsNum",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-2 pb-2"},[_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(item.domainsNum)+" / "+_vm._s(item.activeDomainsNum)+" ")])])]}},{key:"action-buttons",fn:function(ref){
var item = ref.item;
return [_c('table-btn',{attrs:{"item":item,"disabled":item.loading,"tooltip-text":_vm.$t('domains.cloudflare.table.btn.auth'),"icon":"key-chain-variant","can":'domains.cloudflare.auth',"fn":_vm.getCredentials}}),_c('table-btn',{attrs:{"item":item,"disabled":item.loading,"tooltip-text":_vm.$t('domains.cloudflare.table.btn.zoneAdd'),"icon":"plus","can":'domains.cloudflare.create',"fn":_vm.zoneAdd}}),_c('table-btn',{attrs:{"item":item,"loading":item.loading,"tooltip-text":_vm.$t('domains.cloudflare.table.btn.import'),"icon":"database-import-outline","can":'domains.cloudflare.import',"fn":_vm.importDomains},on:{"update:item":function($event){item=$event}}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(_vm.detailsComponent,{tag:"component",attrs:{"params":item}})],1)]}}],null,true)}),(this.$can('domains.cloudflare.create'))?_c('dialogs-cloudflare-zone',{attrs:{"dialog":_vm.zoneDialog,"edited-item":_vm.zoneEditedItem,"action":'create'},on:{"close":_vm.closeZoneDialog}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }