<template>
  <div class="mt-3 mb-3">
    <v-text-field
      ref="search"
      v-model="search"
      hide-details
      single-line
      clearable
      append-icon="mdi-magnify"
      class="mb-5"
      placeholder="Enter keywords..."
    />
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :search="search"
      :show-expand="showExpand"
      :single-expand="singleExpand"
      :hide-default-footer="true"
      :disable-pagination="true"
      :items-per-page="-1"
      :item-class="zoneRowClass"
      :default-table-headers="[]"
      item-key="name"
      class="elevation-1 zones-table"
    >
      <template
        #[`item.name`]="{ item }"
      >
        <a
          :href="'https://' + item.name"
          target="_blank"
          @dblclick="$helper.openWindow(item.name)"
          @click.prevent=""
        >{{ item.name }}</a>
      </template>
      <template
        #[`item.cloudflare_status`]="{ item }"
      >
        {{ item.cloudflare_status || '&mdash;' }}
      </template>
      <template
        #[`item.cloudflare_ssl`]="{ item }"
      >
        {{ item.cloudflare_ssl || '&mdash;' }}
      </template>
      <template
        #[`item.actions`]="{ item }"
      >
        <div class="actions-cell">
          <table-btn
            :item="item"
            :loading="item.loadingPurgeCache"
            :tooltip-text="$t('domains.table.actionsList.cloudflare.clearZoneCache')"
            :icon="`weather-cloudy-clock`"
            :can="`domains.cloudflare.purgeCache`"
            :fn="clearZoneCache"
          />
          <table-btn
            :item="item"
            :loading="item.loading"
            :tooltip-text="$t('domains.cloudflare.table.btn.sync')"
            :icon="`autorenew`"
            :can="`domains.cloudflare.sync`"
            :fn="zoneSync"
          />
          <table-btn
            :item="item"
            :disabled="item.loading || !item.is_cloudflare_active"
            :tooltip-text="$t('domains.cloudflare.table.btn.addDns')"
            :icon="`plus`"
            :can="`domains.cloudflare.create`"
            :fn="dnsAdd"
          />
          <table-btn
            :item="item"
            :disabled="item.loading || !item.is_cloudflare_active"
            :tooltip-text="$t('domains.cloudflare.table.btn.zoneEdit')"
            :icon="`pencil`"
            :can="`domains.cloudflare.update`"
            :fn="zoneEdit"
          />
          <table-btn
            :item="item"
            :disabled="item.loading"
            :tooltip-text="$t('domains.cloudflare.table.btn.removeZone')"
            :icon="`delete`"
            :can="`domains.cloudflare.remove`"
            :fn="zoneRemove"
          />
        </div>
      </template>
      <template
        #[`item.dns_records`]="{ item }"
      >
        {{ item.is_cloudflare_active ? Object.keys(item.dns_records).length : '&mdash;' }}
      </template>

      <template
        #[`item.cloudflare_always_use_https`]="{ item }"
      >
        <v-layout justify-center>
          <v-flex
            xs12
            md4
          >
            <v-switch
              :input-value="item.cloudflare_always_use_https"
              hide-details
              disabled
            />
          </v-flex>
        </v-layout>
      </template>

      <template
        #[`item.cloudflare_automatic_https_rewrites`]="{ item }"
      >
        <v-layout justify-center>
          <v-flex
            xs12
            md4
          >
            <v-switch
              :input-value="item.cloudflare_automatic_https_rewrites"
              hide-details
              disabled
            />
          </v-flex>
        </v-layout>
      </template>

      <template
        #[`item.cloudflare_dev_mode`]="{ item }"
      >
        <v-layout justify-center>
          <v-flex
            xs12
            md4
          >
            <v-switch
              :input-value="item.cloudflare_dev_mode"
              hide-details
              disabled
            />
          </v-flex>
        </v-layout>
      </template>

      <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
        <v-btn
          :disabled="!item.is_cloudflare_active"
          icon
          @click="expand(!isExpanded)"
        >
          <v-icon small>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <component
            :is="detailsComponent"
            :params="item"
          />
        </td>
      </template>
    </v-data-table>
    <dialogs-cloudflare-zone
      :dialog="zoneDialog"
      :edited-item="zoneEditedItem"
      :action="'update'"
      @close="closeZoneDialog"
    />
    <dialogs-cloudflare-dns
      :dialog="dnsDialog"
      :edited-item="dnsEditedItem"
      :action="'create'"
      @close="closeDnsDialog"
    />
  </div>
</template>

<script>
import DialogsCloudflareDns from '../dialogs/CloudflareDns'
import DialogsCloudflareZone from '../dialogs/CloudflareZone'
import CloudflareZoneDetails from './CloudflareZoneDetails'

export default {
  name: 'CloudflareDetails',
  components: {
    DialogsCloudflareDns,
    DialogsCloudflareZone
  },
  props: {
    params: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    loading: false,
    showExpand: true,
    singleExpand: true,
    detailsComponent: CloudflareZoneDetails,
    headers: [],
    items: [],
    search: '',
    // ZONE
    zoneDialog: false,
    zoneEditedItem: {},
    // DNS RECORD
    dnsDialog: false,
    dnsEditedItem: {}
  }),
  computed: {
    getLockedEntities() {
      return this.$store.getters['lockedEntities/getLockedEntities']
    }
  },
  watch: {
    params (val) {
      this.items = val.domains
    }
  },
  async mounted () {
    this.items = this.params.domains
    this.headers = [
      {
        text: this.$t('domains.cloudflare.zones.table.name'),
        align: 'start',
        value: 'name'
      },
      {
        text: this.$t('domains.cloudflare.zones.table.records'),
        value: 'dns_records',
        width: '100px',
        align: 'center',
        sortable: false
      },
      {
        text: this.$t('domains.cloudflare.zones.table.alwUseHttps'),
        value: 'cloudflare_always_use_https',
        width: '120px',
        align: 'center'
      },
      {
        text: this.$t('domains.cloudflare.zones.table.autoHttpsRewrite'),
        value: 'cloudflare_automatic_https_rewrites',
        width: '130px',
        align: 'center'
      },
      {
        text: this.$t('domains.cloudflare.zones.table.devMode'),
        value: 'cloudflare_dev_mode',
        width: '120px',
        align: 'center'
      },
      {
        text: this.$t('domains.cloudflare.zones.table.status'),
        value: 'cloudflare_status',
        width: '100px',
        align: 'center'
      },
      {
        text: this.$t('domains.cloudflare.zones.table.ssl'),
        value: 'cloudflare_ssl',
        width: '100px',
        align: 'center'
      }
      /* ,{ text: '', value: 'data-table-expand' } */
    ]

    if (
      this.$can('domains.cloudflare.purgeCache') ||
      this.$can('domains.cloudflare.sync') ||
      this.$can('domains.cloudflare.create') ||
      this.$can('domains.cloudflare.update') ||
      this.$can('domains.cloudflare.remove')
    ) {
      this.headers.push({
        text: this.$t('domains.cloudflare.zones.table.actions'),
        value: 'actions',
        width: '150px',
        align: 'center',
        sortable: false
      })
    }

    this.$eventBus.$on('updateLockedState', (data) => {
      const itemIndex = this.items.findIndex((fi) => fi._id === data.id)
      if (itemIndex > -1) {
        this.$set(this.items[itemIndex], 'loading', data.lockState)
      }
    })
  },
  methods: {
    /* ------------------------------------------ */
    // ZONES
    /* ------------------------------------------ */
    async zoneSync (item) {
      this.$set(item, 'loading', true)
      try {
        const response = await this.$store.dispatch('cloudflare/zoneSync', {
          id: item.cloudflare_id,
          zoneId: item.cloudflare_zone_id,
          name: item.name
        })
        if (response.success === true) {
          // Object.assign(item, response.result)
          // await this.$toast.success(this.$t('domains.cloudflare.zones.sync.success'))
        } else {
          await this.$toast.error(response.errors)
        }
      } catch (e) {
        console.log(e)
      } finally {
        // this.$set(item, 'loading', true)
      }
    },
    zoneEdit(item) {
      this.zoneEditedItem = Object.assign({}, {
        id: item.cloudflare_id,
        zoneId: item.cloudflare_zone_id,
        name: item.name,
        ip: item.address_record || '',
        ssl: item.cloudflare_ssl || 'full',
        autoHttpsRewrite: item.cloudflare_automatic_https_rewrites || false,
        alwUseHttps: item.cloudflare_always_use_https || false,
        devMode: item.cloudflare_dev_mode || false
      })
      this.zoneDialog = true
    },
    zoneRemove(item) {
      this.zoneEditedItem = Object.assign({}, {
        id: item.cloudflare_id,
        zoneId: item.cloudflare_zone_id,
        name: item.name
      })
      this.$emit('zoneRemove', this.zoneEditedItem)
    },
    closeZoneDialog() {
      this.zoneDialog = false
      this.zoneEditedItem = {}
    },
    zoneRowClass (item) {
      let classes
      switch (item.cloudflare_status) {
        case 'active':
          classes += ' blue lighten-5 '
          break
        case 'pending':
          classes += ' yellow lighten-3 '
          break
        default:
          classes += ' red lighten-5 '
          break
      }
      if (this.getLockedEntities.indexOf(item._id) > -1) {
        classes += ' blue-grey lighten-5 loading-background '
      }
      return classes
    },
    /* ------------------------------------------ */
    // DNS
    /* ------------------------------------------ */
    dnsAdd (item) {
      this.dnsEditedItem = Object.assign({}, {
        id: item.cloudflare_id,
        zoneId: item.cloudflare_zone_id,
        name: '',
        type: item.type || 'A',
        content: item.content || '',
        proxied: item.proxied || true
      })
      this.dnsDialog = true
    },
    closeDnsDialog () {
      this.dnsDialog = false
      this.dnsEditedItem = {}
    },
    async clearZoneCache (item, e) {
      this.$set(item, 'loadingPurgeCache', true)
      try {
        const response = await this.$store.dispatch('cloudflare/purgeCache', {
          id: item.cloudflare_id,
          zoneId: item.cloudflare_zone_id
        })
        if (response.success === true) {
          // await this.$store.commit('__domains.cloudflare/saveItem', response.data.result, { root: true })
          await this.$toast.success(this.$t('domains.cloudflare.zones.purgeCache.success'))
        } else {
          await this.$toast.error(response.errors)
        }
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        this.$set(item, 'loadingPurgeCache', false)
      }
    }
  }
}
</script>

<style lang="scss">
.zones-table {
  .v-data-table-header {
    background: #F5F5F5;
  }
}
.switch-center {
  display: flex;
  justify-content: center;
}
</style>
