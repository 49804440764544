<template>
  <div class="mt-3 mb-3">
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :show-expand="showExpand"
      :hide-default-footer="true"
      :items-per-page="-1"
      class="elevation-1"
    >
      <template
        #[`item.name`]="{ item }"
      >
        <a
          :href="'https://' + item.name"
          target="_blank"
          @dblclick="$helper.openWindow(item.name)"
          @click.prevent=""
        >{{ item.name }}</a>
      </template>
      <template
        #[`item.actions`]="{ item }"
      >
        <div style="width: 80px">
          <table-btn
            :item="item"
            :tooltip-text="$t('domains.cloudflare.dns.table.btn.update')"
            :icon="`pencil`"
            :can="`domains.cloudflare.update`"
            :fn="dnsUpdate"
          />
          <table-btn
            :item="item"
            :tooltip-text="$t('domains.cloudflare.dns.table.btn.remove')"
            :icon="`delete`"
            :can="`domains.cloudflare.remove`"
            :fn="dnsRemove"
          />
        </div>
      </template>

      <template
        #[`item.proxied`]="{ item }"
      >
        <v-switch
          :input-value="item.proxied"
          hide-details="auto"
          disabled
        />
      </template>

      <template
        #[`item.count`]="{ item }"
      >
        {{ Object.values(item.dns_records).length }}
      </template>

      <template #[`item.content`]="{ item }">
        <div class="text-no-wrap">
          <v-chip-group
            active-class="primary--text"
            column
          >
            <v-chip
              small
              class="ma-2 chip-with-copy"
              close
              close-icon="mdi-content-copy"
              @click:close="$copyToClipboard(item.content)"
            >
              {{ item.content }}
            </v-chip>
          </v-chip-group>
        </div>
      </template>
    </v-data-table>
    <dialogs-cloudflare-dns
      :dialog="dnsDialog"
      :edited-item="dnsEditedItem"
      :action="'update'"
      @close="closeDnsDialog"
    />
  </div>
</template>

<script>
import DialogsCloudflareDns from '../dialogs/CloudflareDns'
export default {
  name: 'CloudflareZoneDetails',
  components: {
    DialogsCloudflareDns
  },
  props: {
    params: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    title: '',
    loading: false,
    dialog: false,
    editedItem: {},
    formTitle: '',
    showExpand: false,
    headers: [],
    // DNS
    dnsDialog: false,
    dnsFormTitle: '',
    dnsFormScheme: {},
    dnsSaveLoading: false,
    dnsEditedItem: {},
    dnsDialogDelete: false,
    dnsRemoveLoading: false
  }),
  computed: {
    items () {
      return Object.values(this.params.dns_records)
    }
  },
  mounted () {
    this.setDnsEditedItemDefaultVal()
    this.headers = [
      {
        text: this.$t('domains.cloudflare.dns.table.name'),
        align: 'start',
        value: 'name'
      },
      {
        text: this.$t('domains.cloudflare.dns.table.content'),
        value: 'content'
      },
      {
        text: this.$t('domains.cloudflare.dns.table.type'),
        value: 'type',
        width: '100px',
        align: 'center'
      },
      {
        text: this.$t('domains.cloudflare.dns.table.proxied'),
        value: 'proxied',
        width: '100px',
        align: 'center'
      },
      {
        text: this.$t('domains.cloudflare.dns.table.actions'),
        value: 'actions',
        width: '100px',
        align: 'center'
      }
    ]
    this.dnsFormScheme = {
      type: {
        type: 'select',
        label: this.$t('domains.cloudflare.dns.dialog.type'),
        items: ['A', 'CNAME', 'TXT'],
        createRules: 'req',
        md: 4
      },
      name: {
        type: 'text',
        label: this.$t('domains.cloudflare.dns.dialog.name'),
        createRules: 'req',
        updateRules: [],
        md: 4
      },
      content: {
        type: 'text',
        label: this.$t('domains.cloudflare.dns.dialog.content'),
        createRules: 'req',
        updateRules: [],
        md: 4
      },
      ttl: {
        type: 'number',
        label: this.$t('domains.cloudflare.dns.dialog.ttl'),
        createRules: [],
        updateRules: [],
        md: 6
      },
      proxied: {
        type: 'switch',
        label: this.$t('domains.cloudflare.dns.dialog.proxied'),
        createRules: 'req',
        updateRules: [],
        md: 6,
        class: 'mt-3 ml-5'
      }
    }
  },
  methods: {
    dnsUpdate(item) {
      this.dnsEditedItem = Object.assign({}, {
        id: this.params.cloudflare_id,
        zoneId: item.zone_id,
        dnsZoneId: item.id,
        type: item.type,
        name: item.name,
        content: item.content,
        ttl: item.ttl,
        proxied: Boolean(item.proxied)
      })
      this.dnsDialog = true
    },
    dnsRemove (item) {
      // this.setDnsEditedItemDefaultVal()
      this.dnsEditedItem = Object.assign({}, {
        id: this.params.cloudflare_id,
        zoneId: item.zone_id,
        dnsZoneId: item.id,
        name: item.name
      })
      this.$emit('zoneDnsRemove', this.dnsEditedItem)
    },
    closeDnsDialog () {
      this.dnsDialog = false
      this.setDnsEditedItemDefaultVal()
    },
    setDnsEditedItemDefaultVal() {
      this.dnsEditedItem = Object.assign({}, {
        id: this.params.cloudflare_id || undefined
      })
    }
  }
}
</script>

<style lang="scss">
.v-input--selection-controls {
  margin-top: 0px;
}
</style>
