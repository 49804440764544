var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3 mb-3"},[_c('v-text-field',{ref:"search",staticClass:"mb-5",attrs:{"hide-details":"","single-line":"","clearable":"","append-icon":"mdi-magnify","placeholder":"Enter keywords..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1 zones-table",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"show-expand":_vm.showExpand,"single-expand":_vm.singleExpand,"hide-default-footer":true,"disable-pagination":true,"items-per-page":-1,"item-class":_vm.zoneRowClass,"default-table-headers":[],"item-key":"name"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'https://' + item.name,"target":"_blank"},on:{"dblclick":function($event){return _vm.$helper.openWindow(item.name)},"click":function($event){$event.preventDefault();}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.cloudflare_status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cloudflare_status || '—')+" ")]}},{key:"item.cloudflare_ssl",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cloudflare_ssl || '—')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions-cell"},[_c('table-btn',{attrs:{"item":item,"loading":item.loadingPurgeCache,"tooltip-text":_vm.$t('domains.table.actionsList.cloudflare.clearZoneCache'),"icon":"weather-cloudy-clock","can":"domains.cloudflare.purgeCache","fn":_vm.clearZoneCache}}),_c('table-btn',{attrs:{"item":item,"loading":item.loading,"tooltip-text":_vm.$t('domains.cloudflare.table.btn.sync'),"icon":"autorenew","can":"domains.cloudflare.sync","fn":_vm.zoneSync}}),_c('table-btn',{attrs:{"item":item,"disabled":item.loading || !item.is_cloudflare_active,"tooltip-text":_vm.$t('domains.cloudflare.table.btn.addDns'),"icon":"plus","can":"domains.cloudflare.create","fn":_vm.dnsAdd}}),_c('table-btn',{attrs:{"item":item,"disabled":item.loading || !item.is_cloudflare_active,"tooltip-text":_vm.$t('domains.cloudflare.table.btn.zoneEdit'),"icon":"pencil","can":"domains.cloudflare.update","fn":_vm.zoneEdit}}),_c('table-btn',{attrs:{"item":item,"disabled":item.loading,"tooltip-text":_vm.$t('domains.cloudflare.table.btn.removeZone'),"icon":"delete","can":"domains.cloudflare.remove","fn":_vm.zoneRemove}})],1)]}},{key:"item.dns_records",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_cloudflare_active ? Object.keys(item.dns_records).length : '—')+" ")]}},{key:"item.cloudflare_always_use_https",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-switch',{attrs:{"input-value":item.cloudflare_always_use_https,"hide-details":"","disabled":""}})],1)],1)]}},{key:"item.cloudflare_automatic_https_rewrites",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-switch',{attrs:{"input-value":item.cloudflare_automatic_https_rewrites,"hide-details":"","disabled":""}})],1)],1)]}},{key:"item.cloudflare_dev_mode",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-switch',{attrs:{"input-value":item.cloudflare_dev_mode,"hide-details":"","disabled":""}})],1)],1)]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"disabled":!item.is_cloudflare_active,"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-down ")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(_vm.detailsComponent,{tag:"component",attrs:{"params":item}})],1)]}}],null,true)}),_c('dialogs-cloudflare-zone',{attrs:{"dialog":_vm.zoneDialog,"edited-item":_vm.zoneEditedItem,"action":'update'},on:{"close":_vm.closeZoneDialog}}),_c('dialogs-cloudflare-dns',{attrs:{"dialog":_vm.dnsDialog,"edited-item":_vm.dnsEditedItem,"action":'create'},on:{"close":_vm.closeDnsDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }